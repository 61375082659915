import "../styles/Result.css";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import {
  search_result_0,
  search_result_1,
  search_result_2,
  search_result_3,
} from "../content/search_result";
import {
  news_content,
  news_content_55,
  news_content_60,
  news_content_61,
  news_content_65,
  result_images,
  result_news_jjy,
} from "../content/news_content";
import IconsFunc, { icons } from "../common/IconsFunc";

import ResultContent from "./ResultContent";
import News from "./News";
import Shop from "./Shop";
import ResultImage from "./ResultImage";
import NewsSide from "./News/NewsSide";
import NewsRight from "./News/NewsRight";
import ResultLeft from "./Result/ResultLeft";
import {
  adv_list,
  adv_list_setting,
  place_list,
  place_list_setting,
} from "../content/content_page_65";
import { adv_list_75, news_content_75, place_list_75 } from "../content/content_page_75";

function Result() {
  const [page, setPage] = useState(0);
  // const [isNews, setNews] = useState(false);
  const { query, ssc, setSearchParams } = useOutletContext();

  useEffect(() => {
    if (Number(query)) {
      setPage(Number(query));
      console.log(`this_page_${page}`);
      return;
    }

    if (query) {
      setPage(75);
      return;
    }

    if (query.includes("봉토")) {
      if (ssc === "image") {
        setPage(55);
      } else {
        setPage(50);
      }
    } else if (query.includes("서정원")) {
      setSearchParams(`ssc=news&query=${query}`);
      setPage(60);
    } else if (query.includes("제이스")) {
      setSearchParams(`ssc=news&query=${query}`);
      setPage(70);
    } else if (query.includes("산부인과")) {
      setPage(65);
    } else if (query.includes("헬스") || query.includes("피트니스")) {
      setPage(75);
    }
  }, [query, ssc, setSearchParams, page]);

  return (
    <div className="Result">
      <div className="w1200" id="Result">
        {page === 75 ? (
          <>
            <ResultLeft
              title={`광고`}
              titleBtn={[`등록 안내`]}
              content={adv_list_75}
              strong={query}
              setting={adv_list_setting}
              end={`더보기 →`}
            />

            <News
              icons={icons}
              newsContent={news_content_75}
              type={1}
              bottom={` `}
              strong={query}
            />

            <ResultLeft
              title={`장소`}
              titleBtn={[`내 업체 등록`, `신규장소 등록`]}
              content={place_list_75}
              strong={query}
              setting={place_list_setting}
              middleBar={[
                [`헬스장 ↓`, `야간/휴일 ↓`, `영업중`, `예약`],
                0,
              ]}
              end={`지도에서 보기 →`}
            />

            <NewsRight icons={icons} />
          </>
        ) : page === 65 ? (
          <>
            <ResultLeft
              title={`광고`}
              titleBtn={[`등록 안내`]}
              content={adv_list}
              strong={query}
              setting={adv_list_setting}
              end={`더보기 →`}
            />

            <ResultLeft
              title={`장소`}
              titleBtn={[`내 업체 등록`, `신규장소 등록`]}
              content={place_list}
              strong={query}
              setting={place_list_setting}
              middleBar={[
                [`산부인과 ↓`, `전문의`, `야간/휴일 ↓`, `진료중`, `예약`],
                0,
              ]}
              end={`지도에서 보기 →`}
            />

            <News
              icons={icons}
              newsContent={news_content_65}
              strong={query}
              // newsViews={news_content_55.slice()}
            />

            <NewsRight icons={icons} />
          </>
        ) : page === 50 ? (
          <>
            <News
              icons={icons}
              newsContent={news_content_55}
              newsViews={news_content_55.slice()}
            />
          </>
        ) : page === 55 ? (
          <ResultImage result_images={result_images} />
        ) : page === 60 ? (
          <>
            <News
              icons={icons}
              newsContent={news_content_60}
              newsViews={news_content_60.slice()}
              type={1}
            />

            <NewsSide setPage={setPage} />
          </>
        ) : page === 70 ? (
          <>
            <News
              icons={icons}
              newsContent={result_news_jjy}
              newsViews={result_news_jjy.slice()}
              type={1}
            />

            <NewsSide setPage={setPage} />
          </>
        ) : page === 61 ? (
          <>
            <News
              icons={icons}
              newsContent={news_content_61}
              newsViews={news_content_61.slice()}
              type={1}
            />

            <NewsSide setPage={setPage} />
          </>
        ) : page === 1 ? (
          <>
            <News icons={icons} newsContent={news_content} />

            {search_result_0.map((e, i) => {
              return <ResultContent key={i} title={e.title} body={e.body} />;
            })}
          </>
        ) : page === 2 ? (
          <>
            <Shop icons={icons} iconsFunc={(i) => IconsFunc(i)} />

            {search_result_1.map((e, i) => {
              return <ResultContent key={i} title={e.title} body={e.body} />;
            })}
          </>
        ) : page === 3 ? (
          search_result_2.map((e, i) => {
            return <ResultContent key={i} title={e.title} body={e.body} />;
          })
        ) : page === 4 ? (
          <>
            <div className="ResultContent">
              <div id="result_none_title">
                <span>'현곡리 뺑소니'</span>에 대한 검색결과가 없습니다.
              </div>
              <div className="result_none">
                ㆍ단어의 철자가 정확한지 확인해 보세요.
              </div>
              <div className="result_none">
                ㆍ한글을 영어로 혹은 영어를 한글로 입력했는지 확인해 보세요.
              </div>
              <div className="result_none">
                ㆍ검색어의 단어 수를 줄이거나, 보다 일반적인 검색어로 다시
                검색해 보세요.
              </div>
              <div className="result_none">
                ㆍ두 단어 이상의 검색어인 경우, 띄어쓰기를 확인해 보세요. LEGOO
                맞춤법 검사기
              </div>
              <div className="result_none">
                ㆍ검색 옵션을 변경해서 다시 검색해 보세요.
              </div>
              <div className="mb10"></div>
            </div>
            <div id="result_question">
              <div>
                만족스러운 검색결과를 찾지 못하셨다면 아래 기능도 이용해 보세요.
              </div>
              <div className="dFlex" id="result_question_btn">
                <div className="fHigh2 mr10">지식 질문하기</div>
                <div className="fGray mr10">|</div>
                <div className="fHigh2">검색 도움말 보기</div>
              </div>
            </div>
          </>
        ) : page === 5 ? (
          search_result_3.map((e, i) => {
            return <ResultContent key={i} title={e.title} body={e.body} />;
          })
        ) : (
          <></>
        )}

        {page === 4 ? (
          <></>
        ) : (
          <>
            <div id="result_more">{"검색결과 더보기 →"}</div>

            <div id="result_question">
              <div>
                만족스러운 검색결과를 찾지 못하셨다면 아래 기능도 이용해 보세요.
              </div>
              <div className="dFlex" id="result_question_btn">
                <div className="fHigh2 mr10">지식 질문하기</div>
                <div className="fGray mr10">|</div>
                <div className="fHigh2">검색 도움말 보기</div>
              </div>
            </div>

            <div id="result_pages">
              <div>{"<"}</div>
              <div id="reulst_pages_num">
                <div className="fHigh fBold">1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
                <div>6</div>
                <div>7</div>
                <div>8</div>
                <div>9</div>
                <div>10</div>
              </div>
              <div>{">"}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Result;
