import { cafe_info } from "../components/Cafe/cafe_info";

const adv_list_75 = [
  {
    name: `fullbody.co.kr`,
    sub: `풀바디PT`,
    title: `책임지고 만들어드립니다. 헬스PT 풀바디`,
    body: `다이어트부터 체형 교정까지 한 번에 해결하세요. 올해는 다르게! 상담 환영`,
    // img: ``,
  },
  {
    name: `greattraining12.kr`,
    sub: `GT12`,
    title: `결과로 증명하는 피트니스 GT12`,
    body: `대학생들도 찾아오는 찐 실력 보장 센터 다이어트 결심, 올 여름에는 자신있게 지금부터 준비하세요`,
    // img: ``,
  },
  {
    name: `slimleg.com`,
    sub: `슬림레그`,
    title: `믿고 맡기는 하체 관리 전문`,
    body: `슬림레그만의 노하우로 이루어지는 하체 관리 체형별 맞춤 솔루션으로 완벽하게 관리하자`,
    // img: ``,
  },
];

const place_list_75 = [
  [
    {
      title: `휘트니스 리니에`,
      body: `영업중ㆍ24:00 영업종료`,
      place: [
        `여성전용 PT전문 쾌적한공간`,
        `서울 관악구 신림동 ↓`,
        `방문자 리뷰 687 ㆍ 블로그 리뷰 562`,
        ``,
      ],
      // img: ``,
    },
    {
      title: `성진 피트니스`,
      body: `영업중ㆍ23:00 영업종료`,
      place: [
        `영수증 리뷰 이벤트 진행 중`,
        `서울 관악구 신림동 ↓`,
        `방문자 리뷰 299 ㆍ 블로그 리뷰 3`,
        ``,
      ],
      // img: ``,
    },
    {
      title: `짐앤장`,
      body: `영업중ㆍ24:00 영업종료`,
      place: [
        ``,
        `서울 마포구 노고산동 ↓`,
        `방문자 리뷰 433 ㆍ 블로그 리뷰 2,089`,
        ``,
      ],
      // img: ``,
    },
  ],
  [
    {
      title: `피트니스 황`,
      body: `영업중ㆍ24:00 영업종료`,
      place: [
        `알림받기 하면 포인트 지급 이벤트`,
        `서울 광진구 화양동 ↓`,
        `방문자 리뷰 866 ㆍ 블로그 리뷰 1,007`,
        ``,
      ],
      // img: ``,
    },
    {
      title: `바디플짐`,
      body: `영업중ㆍ24:00 영업종료`,
      place: [
        `24시 운영, 입상경력 바디디자이너`,
        `서울 강남구 신사동 ↓`,
        `방문자 리뷰 1,582 ㆍ 블로그 리뷰 1,579`,
        ``,
      ],
      // img: ``,
    },
    {
      title: `24시 헬스H`,
      body: `영업중`,
      place: [
        ``,
        `서울 구로구 구로동 ↓`,
        `방문자 리뷰 2,954 ㆍ 블로그 리뷰 618`,
        ``,
      ],
      // img: ``,
    },
  ],
];

const news_content_75 = [
  {
    news: {
      name: cafe_info.momjjang.name,
      icon: cafe_info.momjjang.logo,
      more: cafe_info.momjjang.more
    },
    img: cafe_info.momjjang.logo,
    title: cafe_info.momjjang.title,
    body: `안녕하세요 몸짱카페 입니다. 몸짱이 되고싶은 사람들을 위한 공간입니다. 멋진몸을 만든는데 필요한 다양한 정보 교환하고 얻어가시면 좋겠습니다. 몸짱카페에 오신 여러분을 응원합니다.`,
    func: () => {
      window.location = "/cafe/momjjang";
    }
  },
  {
    news: {
      name: cafe_info.obesehealth.name,
      icon: cafe_info.obesehealth.logo,
      more: cafe_info.obesehealth.more
    },
    img: cafe_info.obesehealth.logo,
    title: cafe_info.obesehealth.title,
    body: `LEGOO 유일 비만으로 고민하는 사람들이 모여 서로의 경험과 정보를 공유하고, 함께 건강한 습관을 만들어가는 공간입니다. 다양한 정보: 나에게 맞는 운동 방법, 식단 관리 팁, 건강 레시피 등 유용한 정보가 가득! 전문가 칼럼, 성공 후기, 최신 건강 트렌드까지 한눈에!`,
    func: () => {
      window.location = "/cafe/obesehealth";
    }
  },
  {
    news: {
      name: cafe_info.mr5.name,
      icon: cafe_info.mr5.logo,
      more: cafe_info.mr5.more
    },
    img: cafe_info.mr5.logo,
    title: cafe_info.mr5.title,
    body: `멸치는 이제 그만! 건강하게 벌크업 하고 싶다면? "마른파이브" | 마른 사람들이 모여 서로의 경험과 정보를 공유하고, 함께 건강한 몸을 만들어가는 공간입니다. 건강하게 체중 증량을 하고 싶은 분 혼자 운동하기 힘들어 동기 부여가 필요한 분 나와 비슷한 체형의 사람들과 소통하고 싶은 분 건강 정보를 얻고 싶은 분`,
    func: () => {
      window.location = "/cafe/mr5";
    }
  },
  {
    news: {
      name: cafe_info.healthnation.name,
      icon: cafe_info.healthnation.logo,
      more: cafe_info.healthnation.more
    },
    img: cafe_info.healthnation.logo,
    title: cafe_info.healthnation.title,
    body: `안녕하세요 헬스제국 카페 입니다. 헬스를 사랑하는 사람들을 위한 공간입니다. 멋진몸을 만든는데 필요한 다양한 정보 교환하고 얻어가시면 좋겠습니다. 대한민국의 모든 헬스인을 응원합니다. 함께 운동하고, 식단을 공유하며 건강한 습관 만들기! 온/오프라인 모임을 통해 동기 부여 UP!`,
    func: () => {
      window.location = "/cafe/healthnation";
    }
  },
  {
    news: {
      name: cafe_info.deukgeun.name,
      icon: cafe_info.deukgeun.logo,
      more: cafe_info.deukgeun.more
    },
    img: cafe_info.deukgeun.logo,
    title: cafe_info.deukgeun.title,
    body: `LEGOO NO.1 카페, 최다회원, 최다정보, 대표카페, 다이어트, 보충제, 동영상, 홈트레이닝, 살찌우기 | 안녕하세요 대한민국 최고의 바디빌딩 커뮤니티 득근클럽입니다. 모두 득근합시다.`,
    func: () => {
      window.location = "/cafe/deukgeun";
    }
  },
  {
    news: {
      name: cafe_info.gymmania.name,
      icon: cafe_info.gymmania.logo,
      more: cafe_info.gymmania.more
    },
    img: cafe_info.gymmania.logo,
    title: cafe_info.gymmania.title,
    body: `국내대표 헬스, 영양제, 다이어트, 바디프로필 분야에서 가장 활성화된 커뮤니티이며 LEGOO 공식 대표 카페로 선정된 짐마니아 입니다. 초보헬린이 여러분 환영. 서로 정보 교환하고 모두들 화이팅입니다`,
    func: () => {
      window.location = "/cafe/gymmania";
    }
  },
];

export { adv_list_75, place_list_75, news_content_75 };
