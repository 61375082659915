import { icons } from "../../../common/IconsFunc";
import { inbox_list } from "../mail_content";

function MailMainMobile() {
  return (
    <div className={`MailMain true`}>
      <div className="m_header_box">
        <div className="header">
          <div className="btns">
            <div>
              <icons.Menu />
            </div>
          </div>

          <div className="directory">{`Inbox ↓`}</div>

          <div className="fBlank"></div>

          <div className="btns add_span">
            <div>
              <icons.Square />
            </div>
            <span>▼</span>
          </div>

          <div className="btns">
            <div>
              <icons.EllipseCol />
            </div>
          </div>

          <div className="btns end">
            <div>
              <icons.Refresh />
            </div>
          </div>
        </div>
      </div>

      <div className="mail_box">
        <div className="mail_con">
          {inbox_list.map((e, i) => {
            let cname = "mail_col";

            if (e.unread) {
              cname += " unread";
            }

            if (inbox_list.length - 1 === i) {
              cname += " end";
            }

            return (
              <div className={cname + " mobile"} key={`inbox_list_keys_${i}`}>
                <div className="head">
                  <div className="icon checkbox">
                    <div className="icon_box">
                      <icons.Square />
                    </div>
                  </div>

                  <div className="icon star">
                    <div className="icon_box">
                      <icons.Star />
                    </div>
                  </div>

                  <div className="icon bookmark">
                    <div className="icon_box">
                      <icons.Bookmark />
                    </div>
                  </div>

                  <div className="title">{e.sender}</div>
                </div>

                <div
                  className="box mobile"
                  onClick={() => {
                    if (e.id) {
                      window.location = `/mail/${e.id}`;
                    }
                  }}
                >
                  <div className="body">{e.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MailMainMobile;
