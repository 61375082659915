import { icons } from "../../../common/IconsFunc";

// **
// * this is common header component
// * Read the common style
// **

function MailHeaderMobile() {
  const goHome = () => {
    window.location = "/";
  };

  return (
    <div className={`CommonHeaderBox Mobile`}>
      <div className={`CommonHeader WebHardHeader Mobile`}>
        <div className="common_header_logo">
          <img onClick={goHome} src="/images/logo.png" alt="logo" />
          <span>MAIL</span>
        </div>

        <div className="fBlank"></div>

        <div className="common_header_icon">
          <icons.Search />
        </div>
        <div className="common_header_icon">
          <icons.Bell />
        </div>
        <div className="common_header_icon plus">
          <icons.GearB />
        </div>
      </div>

      <div className="CommonHeaderBlank"></div>
    </div>
  );
}

export default MailHeaderMobile;
