import n1 from '../../images/nr_imgs/n1.png';
import n2 from '../../images/nr_imgs/n2.png';
import n3 from '../../images/nr_imgs/n3.png';
import n4 from '../../images/nr_imgs/n4.png';

export default function NewsRight ({ icons }) {
  return (
    <div className='n_right'>
      <div className='nr_list nr_bb'>
        <div className='nr_img'>
          <img src={n1} alt='nr_img' />
        </div>

        <div>
          <div className='fBold'>올해, 여행은 여기로 떠나요</div>
          <div className='fGray f14'>국내 여행지 추천 모아보기</div>
        </div>

        <div className="fBlank"></div>

        <div className='nr_icon'>
          <icons.Right />
        </div>
      </div>
      <div className='nr_list nr_bb'>
        <div className='nr_img'>
          <img src={n2} alt='nr_img' />
        </div>

        <div>
          <div className='fBold'>AI 검색, 아직 안 써봤다면?</div>
          <div className='fGray f14'>LEGOO AI: 사전 신청하기</div>
        </div>

        <div className="fBlank"></div>

        <div className='nr_icon'>
          <icons.Right />
        </div>
      </div>
      <div className='nr_list nr_bb'>
        <div className='nr_img'>
          <img src={n3} alt='nr_img' />
        </div>

        <div>
          <div className='fBold'>LEX와 함께 대화해 보세요</div>
          <div className='fGray f14'>대화형 AI 서비스 시작해 보기</div>
        </div>

        <div className="fBlank"></div>

        <div className='nr_icon'>
          <icons.Right />
        </div>
      </div>
      <div className='nr_list'>
        <div className='nr_img'>
          <img src={n4} alt='nr_img' />
        </div>

        <div>
          <div className='fBold'>이제, LEGOO앱에서 탭하세요!</div>
          <div className='fGray f14'>새로워진 LEGOO앱 살펴보기</div>
        </div>

        <div className="fBlank"></div>

        <div className='nr_icon'>
          <icons.Right />
        </div>
      </div>
    </div>
  )
}