import "./MailHeader.css";
import { icons } from "../../../common/IconsFunc";

// **
// * this is common header component
// * Read the common style
// **

function MailHeader() {
  const goHome = () => {
    window.location = "/";
  };

  return (
    <div className={`CommonHeaderBox`}>
      <div className={`CommonHeader WebHardHeader`}>
        <div className="common_header_logo">
          <img onClick={goHome} src="/images/logo.png" alt="logo" />
          <span>MAIL</span>
        </div>

        <div className="common_search">
          <div className="common_search_icon">
            <icons.Search />
          </div>
          <input
            className="common_search_input"
            placeholder="Search mail"
            type="text"
            autoComplete="off"
            spellCheck="false"
          />
          <div className="common_search_icon">
            <icons.Slider />
          </div>
        </div>

        <div className="fBlank"></div>

        <>
          <div className="common_header_icon plus">
            <icons.GearB />
          </div>
          <div className="common_header_icon">
            <icons.Compass />
          </div>
          <div className="common_header_icon">
            <icons.Bell />
          </div>
          {/* <div className='common_header_icon'>
              <icons.Menu />
            </div> */}
        </>

        <div className="common_header_login">
          MY ↓
        </div>
      </div>

      <div className="CommonHeaderBlank"></div>
    </div>
  );
}

export default MailHeader;
