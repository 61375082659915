import "./MailMain.css";

import { icons } from "../../../common/IconsFunc";
import { inbox_list } from "../mail_content";

function MailMain() {
  return (
    <div className={`MailMain`}>
      <div className="header">
        <div className="btns add_span">
          <div>
            <icons.Square />
          </div>
          <span>▼</span>
        </div>

        <div className="btns">
          <div>
            <icons.Refresh />
          </div>
        </div>

        <div className="btns">
          <div>
            <icons.EllipseCol />
          </div>
        </div>

        <div className="directory">{`Inbox ↓`}</div>

        <div className="fBlank"></div>

        <div className="btns">
          <div>
            <icons.Menu />
          </div>
        </div>

        <div className="btns end">
          <div>
            <icons.Table />
          </div>
        </div>
      </div>

      <div className="mail_box">

        <div className="mail_con">

          {inbox_list.map((e, i) => {
            let cname = "mail_col";

            if (e.unread) {
              cname += " unread";
            }

            if (inbox_list.length - 1 === i) {
              cname += " end";
            }

            return (
              <div className={cname} key={`inbox_list_keys_${i}`}>
                <div className="icon checkbox">
                  <div className="icon_box">
                    <icons.Square />
                  </div>
                </div>

                <div className="icon star">
                  <div className="icon_box">
                    <icons.Star />
                  </div>
                </div>

                <div className="icon bookmark">
                  <div className="icon_box">
                    <icons.Bookmark />
                  </div>
                </div>

                <div className="box" onClick={() => {
                  if (e.id) {
                    window.location = `/mail/${e.id}`;
                  }
                }}>
                  <div className="title">{e.sender}</div>

                  <div className="body">{e.title}</div>
                </div>

                <div className="toggle_box">
                  <div className="icon">
                    <div className="icon_box">
                      <icons.DownloadCircle />
                    </div>
                  </div>

                  <div className="icon">
                    <div className="icon_box">
                      <icons.TrashBin />
                    </div>
                  </div>

                  <div className="icon">
                    <div className="icon_box">
                      <icons.Clock />
                    </div>
                  </div>

                  <div className="icon">
                    <div className="icon_box">
                      <icons.CommentDot />
                    </div>
                  </div>

                  <div className="icon">
                    <div className="icon_box">
                      <icons.ShareLine />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default MailMain;
