import "./Cafe.css";

import { icons } from "../../common/IconsFunc";
import { cicons } from "./cafeIcons";
import { cafe_info } from "./cafe_info";
import { jaritsu } from "../../common/commonFunc";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";

function Cafe() {
  const { cafeId } = useParams();

  const editorRef = useRef(null);
  const apiKey = process.env.REACT_APP_TINY;

  const [ci, setCi] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const custom_css = `
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  `;

  const popDone = useRef(null);

  const openPop = () => {
    popDone.current.style.display = "block";
  };

  const closePop = () => {
    popDone.current.style.display = "none";
  };

  useEffect(() => {
    if (!ci) {
      setCi(cafe_info[cafeId]);
    }
  }, [cafeId, ci]);

  return (
    <div className="Cafe">
      <div id="pop_done" ref={popDone}>
        <div className="box" onClick={closePop}>
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div className="tt">게시글을 등록했습니다.</div>
          <div style={{display: "flex", marginTop: "20px"}}>
            <div className="btn">목록</div>
            <div className="btn">확인</div>
          </div>
        </div>
      </div>

      <div className="header">
        <div className="logo" onClick={() => (window.location = "/")}>
          <img src="/images/logo.png" alt="le" />
        </div>

        <div className="typo" onClick={() => (window.location = "/")}>
          LEGOO
        </div>

        <div className="fBlank"></div>

        <div className="menu">카페홈</div>
        <div className="mb"></div>
        <div className="menu">이웃</div>
        <div className="mb"></div>
        <div className="menu">가입카페 ↓</div>
        <div className="mb"></div>
        <div className="menu">새글</div>
        <div className="mb"></div>
        <div className="menu">내소식</div>
        <div className="mb"></div>
        <div className="menu">채팅</div>
        <div className="mb"></div>

        <div className="ii">
          <icons.Search />
        </div>

        <div className="ii">
          <icons.MessageSquare />
        </div>

        <div className="ii">
          <icons.Menu />
        </div>
      </div>

      {ci ? (
        // <></>
        <>
          {!searchParams.get("write") ? (
            <>
              <div className="banner">
                <img src={ci.banner} alt="banner" />
              </div>

              <div className="middle">
                {ci.middle.map((e, i) => {
                  return (
                    <div key={`middle_key_${i}`} className="mm">
                      <span>ㆍ</span>
                      <div>{e}</div>
                    </div>
                  );
                })}
              </div>

              <div className="main">
                <div className="cf_left">
                  <div className="info">
                    <div
                      className="bb sel"
                      style={{ borderRight: "solid 1px #ddd" }}
                    >
                      카페정보
                    </div>
                    <div className="bb">나의정보</div>
                  </div>

                  <div className="info2">
                    <div className="icon">
                      <img src={ci.logo} alt="logo" />
                    </div>

                    <div className="more">
                      <div
                        className={ci.name.length > 9 ? "b1 lt" : "b1"}
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                          marginBottom: "5px",
                        }}
                      >
                        {ci.name}
                      </div>
                      <div className="b2">
                        <div className="tt">{ci.date + ` 개설`}</div>
                      </div>
                      <div className="b2">
                        <icons.User />
                        <div className="tt">{jaritsu(ci.member)}</div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="btn black"
                    onClick={() => setSearchParams(`write=true`)}
                  >
                    카페 글쓰기
                  </div>

                  <div className="btn">카페 채팅</div>

                  <div className="list">
                    {ci.left.map((e, i) => {
                      return (
                        <div className="block" key={`cf_list_key_${i}`}>
                          {e.map((e2, i2) => {
                            return (
                              <div key={`cf_list_key2_${i}_${i2}`}>
                                {e2[0] === 0 ? (
                                  <div className="title">{e2[1]}</div>
                                ) : (
                                  <div className="bb">
                                    {e2[0] === 1 ? (
                                      <cicons.File />
                                    ) : e2[0] === 2 ? (
                                      <cicons.Camera />
                                    ) : e2[0] === 3 ? (
                                      <cicons.Heart />
                                    ) : e2[0] === 4 ? (
                                      <cicons.Menu />
                                    ) : e2[0] === 5 ? (
                                      <cicons.Clock />
                                    ) : (
                                      <cicons.Video />
                                    )}

                                    <div className="tt">{e2[1]}</div>

                                    {e2[2] ? (
                                      <div className="new">N</div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="cf_right">
                  <div className="bodyimg">
                    <img src={ci.body} alt="bb" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="banner">
                <img src={ci.banner} alt="banner" />
              </div>

              <div className="middle">
                {ci.middle.map((e, i) => {
                  return (
                    <div key={`middle_key_${i}`} className="mm">
                      <span>ㆍ</span>
                      <div>{e}</div>
                    </div>
                  );
                })}
              </div>

              <div className="cf_write">
                <div className="head">
                  <div className="title">카페 글쓰기</div>

                  <div className="fBlank"></div>

                  <div className="btn">임시등록</div>
                  <div className="btn black" onClick={openPop}>
                    등 록
                  </div>
                </div>

                <div className="address">
                  <div className="top">
                    <select id="">
                      <option value="자유게시판">자유게시판</option>
                      <option value="자유게시판">질문게시판</option>
                    </select>

                    <div style={{ width: "50px" }}></div>

                    <select id="" disabled>
                      <option value="말머리 선택">말머리 선택</option>
                    </select>
                  </div>

                  <div className="bot">
                    <input
                      type="text"
                      placeholder="제목을 입력하세요."
                      spellCheck="false"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div id="editorBox">
                  <Editor
                    id="tinyEditor"
                    // inline={true}
                    initialValue={null}
                    ref={editorRef}
                    apiKey={apiKey}
                    init={{
                      toolbar:
                        "styles fontfamily fontsizeinput | link image media table | selectall removeformat copy newdocument remove | " +
                        "undo redo | forecolor bold italic underline strikethrough charmap | superscript subscript | align lineheight | tinycomments | " +
                        "checklist numlist bullist indent outdent",

                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "preview",
                        "wordcount",
                        "quickbars",
                      ],

                      toolbar_mode: "wrap",
                      quickbars_insert_toolbar: false,
                      tinycomments_mode: "embedded",
                      menubar: false,

                      // images
                      automatic_uploads: false,

                      // image_title: true,
                      images_upload_url: "postAcceptor.php",

                      file_picker_types: "image",
                      file_picker_callback: (cb, value, meta) => {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");

                        input.addEventListener("change", (e) => {
                          const file = e.target.files[0];

                          const reader = new FileReader();
                          reader.addEventListener("load", () => {
                            /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                            const id = "blobid" + new Date().getTime();
                            const blobCache =
                              window.tinymce.activeEditor.editorUpload
                                .blobCache;
                            const base64 = reader.result.split(",")[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                          });
                          reader.readAsDataURL(file);
                        });

                        input.click();
                      },

                      browser_spellcheck: false,

                      language: "ko_KR",
                      height: 700,
                      max_height: 700,

                      content_style: custom_css,
                    }}
                    // onEditorChange={onEditorChange}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
}

export default Cafe;
