const cafe_info = {
  healthnation: {
    // body: `안녕하세요 헬스제국 카페 입니다. 헬스를 사랑하는 사람들을 위한 공간입니다. 멋진몸을 만든는데 필요한 다양한 정보 교환하고 얻어가시면 좋겠습니다. 대한민국의 모든 헬스인을 응원합니다.`,
    name: `헬스제국`,
    title: `헬스제국`,
    more: `다이어트/프로틴/단백질/영양제/식단/운동/건강`,
    logo: `/images/healthcafe/1/logo.png`,
    banner: `/images/healthcafe/1/banner.png`,
    body: `/images/healthcafe/1/body.png`,
    date: "2011-03-29",
    member: 221345,
    middle: [
      `전체글보기`,
      `보충제정보`,
      `용품 공동구매`,
      `이미지 모아보기`,
      `동영상 모아보기`,
      `같이 운동해요`,
      `헬스장 정보`,
      `PT 합니다/구합니다`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
        [4, `공동구매`],
      ],
      [
        [1, `공지사항`],
        [1, `헬스제국 스토리`],
        [1, `헬스제국 대회 소식`],
        [1, `헬스제국 제품`],
      ],
      [
        [1, `가입인사`],
        [1, `이벤트게시판`],
        [1, `나눔게시판`],
        [1, `자유게시판`],
        [1, `헬스장 마음의소리`],
      ],
      [
        [0, `질문답변 게시판`],
        [1, `태닝 질문답변`],
        [1, `헬스장 질문답변`],
        [1, `질병ㆍ부상 질문답변`],
        [1, `대회준비 질문답변`],
        [1, `바디프로필 질문답변`],
        [1, `운동방법 질문답변`],
        [1, `머신ㆍ장비 질문답변`],
        [1, `영양ㆍ식단 질문답변`],
        [1, `보충제 질문답변`],
        [1, `자격증 질문답변`],
        [1, `기타 질문답변`],
      ],
      [
        [6, `오늘의 운동`],
        [2, `오늘의 식단`],
      ],
      [
        [1, `대회ㆍ시합 후기`],
        [1, `바디프로필 후기`],
        [1, `다이어트 후기`],
      ],
      [
        [0, `정보 공유★`],
        [1, `시합, 프로필 정보 공유`],
        [1, `생체 정보 공유`],
        [1, `운동 정보 공유`],
        [1, `루틴 정보 공유`],
        [1, `영양 정보 공유`],
        [1, `식단 정보 공유`],
        [1, `근육ㆍ해부학 자료 공유`],
        [1, `재활 정보 공유`],
        [1, `나만의 정보 공유`],
      ],
    ],
  },
  deukgeun: {
    // body: `LEGOO NO.1 카페, 최다회원, 최다정보, 대표카페, 다이어트, 보충제, 동영상, 홈트레이닝, 살찌우기 | 안녕하세요 대한민국 최고의 바디빌딩 커뮤니티 득근클럽입니다. 모두 득근합시다.`,
    name: `득근클럽`,
    title: `득근클럽 - 국내최고 바디빌딩 커뮤니티 WE NEVER STOP`,
    more: `WORKOUT | DIET | NUTRITION | HEALTH | TRAINING`,
    logo: `/images/healthcafe/2/logo.png`,
    banner: `/images/healthcafe/2/banner.png`,
    body: `/images/healthcafe/2/body.png`,
    date: "2014-06-20",
    member: 356789,
    middle: [
      `전체글보기`,
      `공지사항`,
      `질문답변`,
      `운동 TIP`,
      `영양 TIP`,
      `오늘의 운동 일지`,
      `오운완 셀카 인증`,
      `이벤트 게시판`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
      ],
      [
        [0, `득근클럽`],
        [1, `공지사항`],
        [1, `가입인사`],
        [1, `이벤트 게시판`],
        [1, `이벤트 후기`],
      ],
      [
        [0, `커뮤니티`],
        [1, `자유 게시판`],
        [1, `질문 게시판`],
        [1, `일상 게시판`],
        [1, `인바디 게시판`],
        [1, `다이어트 게시판`],
        [1, `프로그램 게시판`],
      ],
      [
        [0, `질문 답변 | 정보 공유`],
        [1, `장비 | 의류 게시판`],
        [1, `운동 | 동작 게시판`],
        [1, `영양 | 식단 게시판`],
        [1, `통증 | 부상 게시판`],
        [1, `오픈 | 대회 게시판`],
        [1, `박스 | 행사 게시판`],
      ],
      [
        [0, `영상 피드백`],
        [1, `동작 피드백`],
        [1, `자세 피드백`],
      ],
      [
        [0, `운동 기록`],
        [1, `오늘의 운동 일지`],
        [1, `오운완 셀카 인증`],
        [1, `성장 기록`],
      ],
    ],
  },
  gymmania: {
    // body: `국내대표 헬스, 영양제, 다이어트, 바디프로필 분야에서 가장 활성화된 커뮤니티이며 LEGOO 공식 대표 카페로 선정된 짐마니아 입니다. 초보헬린이 여러분 환영. 서로 정보 교환하고 모두들 화이팅입니다`,
    name: `짐마니아`,
    title: `짐마니아 - 국내대표 헬스, 영양제, 다이어트, 바디프로필 분야 최대커뮤니티`,
    more: `헬스 커뮤니티 / 바디빌딩 크로스핏 요가 필라테스 바디프로필 GX`,
    logo: `/images/healthcafe/3/logo.png`,
    banner: `/images/healthcafe/3/banner.png`,
    body: `/images/healthcafe/3/body.png`,
    date: "2009-12-03",
    member: 264523,
    middle: [
      `전체글보기`,
      `헬스보충제/용품 공동구매`,
      `제휴업체 이벤트/체험단`,
      `그립패드 후기`,
      `이미지 모아보기`,
      `동영상 모아보기`,
      `보충제 경험담`,
      `헬스 정보`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
        [4, `공동구매`],
      ],
      [
        [1, `제휴업체 이벤트/체험단`],
        [1, `그립패드 후기`],
        [1, `카페 체험단 후기게시판`],
        [1, `카페회원 할인쿠폰`],
      ],
      [
        [0, `- 짐마니아`],
        [1, `공지사항`],
        [1, `FAQ`],
        [1, `10문10답`],
        [1, `가입인사`],
        [1, `출석체크`],
        [1, `등업 신청게시판`],
        [1, `자유게시판`],
        [1, `헬스장 이모저모`],
        [1, `불건전게시물 신고`],
        [1, `성희롱 관련신고`],
      ],
      [
        [0, `- 헬스 정보`],
        [1, `헬스 Q&A`],
        [1, `고수님 HELP ME`],
        [1, `나만의 운동 TIP`],
        [1, `정체기 극복 TIP`],
        [1, `운동기구 사용법`],
        [1, `부위별 운동방법`],
        [1, `홈트레이닝`],
        [1, `스트레칭 필수`],
        [1, `기타 헬스정보`],
      ],
      [
        [6, `오늘의 운동`],
        [2, `오늘의 식단`],
      ],
      [
        [1, `대회ㆍ시합 후기`],
        [1, `바디프로필 후기`],
        [1, `다이어트 후기`],
      ],
      [
        [0, `정보 공유`],
        [1, `시합, 프로필 정보 공유`],
        [1, `생체 정보 공유`],
        [1, `운동 정보 공유`],
        [1, `루틴 정보 공유`],
        [1, `영양 정보 공유`],
        [1, `식단 정보 공유`],
        [1, `근육ㆍ해부학 자료 공유`],
        [1, `재활 정보 공유`],
        [1, `나만의 정보 공유`],
      ],
    ],
  },
  momjjang: {
    name: `몸짱카페`,
    title: `몸짱카페`,
    more: `운동짱, 몸짱, S라인 - 이제는 즐겁게 만들 수 있도록 - 몸짱카페`,
    logo: `/images/healthcafe/4/logo.png`,
    banner: `/images/healthcafe/4/banner.png`,
    body: `/images/healthcafe/3/body.png`,
    date: "2011-03-29",
    member: 221345,
    middle: [
      `전체글보기`,
      `공지사항`,
      `질문답변`,
      `운동 TIP`,
      `영양 TIP`,
      `오늘의 운동 일지`,
      `오운완 셀카 인증`,
      `이벤트 게시판`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
      ],
      [
        [0, `몸짱카페`],
        [1, `공지사항`],
        [1, `가입인사`],
        [1, `이벤트 게시판`],
        [1, `이벤트 후기`],
      ],
      [
        [0, `커뮤니티`],
        [1, `자유 게시판`],
        [1, `질문 게시판`],
        [1, `일상 게시판`],
        [1, `인바디 게시판`],
        [1, `다이어트 게시판`],
        [1, `프로그램 게시판`],
      ],
      [
        [0, `질문 답변 | 정보 공유`],
        [1, `장비 | 의류 게시판`],
        [1, `운동 | 동작 게시판`],
        [1, `영양 | 식단 게시판`],
        [1, `통증 | 부상 게시판`],
        [1, `오픈 | 대회 게시판`],
        [1, `박스 | 행사 게시판`],
      ],
      [
        [0, `영상 피드백`],
        [1, `동작 피드백`],
        [1, `자세 피드백`],
      ],
      [
        [0, `운동 기록`],
        [1, `오늘의 운동 일지`],
        [1, `오운완 셀카 인증`],
        [1, `성장 기록`],
      ],
    ],
  },
  obesehealth: {
    name: `비만인들의 건강 공유`,
    title: `비만인들의 건강 공유`,
    more: `[다이어트 비만 공유 커뮤니티] 비만인들의 건강 공유`,
    logo: `/images/healthcafe/5/logo.png`,
    banner: `/images/healthcafe/5/banner.png`,
    body: `/images/healthcafe/5/body.png`,
    date: "2014-06-20",
    member: 156789,
    middle: [
      `전체글보기`,
      `정보공유`,
      `비만인 스토리`,
      `이벤트게시판`,
      `나눔게시판`,
      `자유게시판`,
      `마음의소리`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
        [4, `공동구매`],
      ],
      [
        [1, `공지사항`],
        [1, `비만인 스토리`],
        [1, `비만인 건강소식`],
        [1, `비만인 제품`],
      ],
      [
        [1, `가입인사`],
        [1, `이벤트게시판`],
        [1, `나눔게시판`],
        [1, `자유게시판`],
        [1, `마음의소리`],
      ],
      [
        [0, `질문답변 게시판`],
        [1, `태닝 질문답변`],
        [1, `헬스장 질문답변`],
        [1, `질병ㆍ부상 질문답변`],
        [1, `대회준비 질문답변`],
        [1, `바디프로필 질문답변`],
        [1, `운동방법 질문답변`],
        [1, `머신ㆍ장비 질문답변`],
        [1, `영양ㆍ식단 질문답변`],
        [1, `보충제 질문답변`],
        [1, `자격증 질문답변`],
        [1, `기타 질문답변`],
      ],
      [
        [6, `오늘의 운동`],
        [2, `오늘의 식단`],
      ],
      [
        [1, `대회ㆍ시합 후기`],
        [1, `바디프로필 후기`],
        [1, `다이어트 후기`],
      ],
      [
        [0, `정보 공유★`],
        [1, `시합, 프로필 정보 공유`],
        [1, `생체 정보 공유`],
        [1, `운동 정보 공유`],
        [1, `루틴 정보 공유`],
        [1, `영양 정보 공유`],
        [1, `식단 정보 공유`],
        [1, `근육ㆍ해부학 자료 공유`],
        [1, `재활 정보 공유`],
        [1, `나만의 정보 공유`],
      ],
    ],
  },
  mr5: {
    name: `마른파이브`,
    title: `마른파이브`,
    more: `마른사람, 마른남자, 마른체형, 마른체질, 외배엽 - 국내 최대 마른분들의 최종 정착지 마른파이브`,
    logo: `/images/healthcafe/6/logo.png`,
    banner: `/images/healthcafe/6/banner.png`,
    body: `/images/healthcafe/6/body.png`,
    date: "2009-12-03",
    member: 264523,
    middle: [
      `전체글보기`,
      `공동구매`,
      `제휴업체 이벤트/체험단`,
      `그립패드 후기`,
      `이미지 모아보기`,
      `동영상 모아보기`,
      `보충제 경험담`,
      `헬스 정보`,
    ],
    left: [
      [
        [1, `전체글보기`],
        [3, `인기글`],
        [4, `공동구매`],
      ],
      [
        [1, `제휴업체 이벤트/체험단`],
        [1, `그립패드 후기`],
        [1, `카페 체험단 후기게시판`],
        [1, `카페회원 할인쿠폰`],
      ],
      [
        [0, `- 마른파이브`],
        [1, `공지사항`],
        [1, `FAQ`],
        [1, `10문10답`],
        [1, `가입인사`],
        [1, `출석체크`],
        [1, `등업 신청게시판`],
        [1, `자유게시판`],
        [1, `헬스장 이모저모`],
        [1, `불건전게시물 신고`],
        [1, `성희롱 관련신고`],
      ],
      [
        [0, `- 헬스 정보`],
        [1, `헬스 Q&A`],
        [1, `고수님 HELP ME`],
        [1, `나만의 운동 TIP`],
        [1, `정체기 극복 TIP`],
        [1, `운동기구 사용법`],
        [1, `부위별 운동방법`],
        [1, `홈트레이닝`],
        [1, `스트레칭 필수`],
        [1, `기타 헬스정보`],
      ],
      [
        [6, `오늘의 운동`],
        [2, `오늘의 식단`],
      ],
      [
        [1, `대회ㆍ시합 후기`],
        [1, `바디프로필 후기`],
        [1, `다이어트 후기`],
      ],
      [
        [0, `정보 공유`],
        [1, `시합, 프로필 정보 공유`],
        [1, `생체 정보 공유`],
        [1, `운동 정보 공유`],
        [1, `루틴 정보 공유`],
        [1, `영양 정보 공유`],
        [1, `식단 정보 공유`],
        [1, `근육ㆍ해부학 자료 공유`],
        [1, `재활 정보 공유`],
        [1, `나만의 정보 공유`],
      ],
    ],
  },
};

export { cafe_info };
