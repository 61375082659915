import { useEffect, useState } from "react";
import "./Mail.css";
import MailHeader from "./MailHeader/MailHeader";
import MailMain from "./MailMain/MailMain";
import MailSidebar from "./MailSidebar/MailSidebar";
import { useParams } from "react-router-dom";
import MailView from "./MailMain/MailView";
import MailHeaderMobile from "./MailHeader/MailHeaderMobile";
import MailMainMobile from "./MailMain/MailMainMobile";

function Mail() {
  const [inbox, setInbox] = useState(9);
  const [isView, setView] = useState(false);
  const [isM, setM] = useState(false);

  const { mailId } = useParams();

  useEffect(() => {
    if (mailId) {
      setView(true);
      setInbox(8);
    } else {
      setView(false);
      setInbox(9);
    }

    if (window.innerWidth < 700) {
      setM(true);
    } else {
      setM(false);
    }
  }, [mailId]);

  return (
    <div className="Mail">
      {
        isM ?
        <MailHeaderMobile />
        :
        <MailHeader />
      }

      <div className="CommonContainer">
        {
          isM ?
          <></>
          :
          <MailSidebar inbox={inbox} />
        }

        {
          isView ?
          <MailView id={mailId} isM={isM} />
          :
          <>
          {
            isM ?
            <MailMainMobile />
            :
            <MailMain />
          }
          </>
        }
      </div>
    </div>
  );
}

export default Mail;
