export default function IconsFunc(i) {
  switch (i) {
    case 0:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0h14v2h2v14H0V0zm2 2v12h12V4h-1.998V2H2zm2 6h8v2H4V8zm0-4h6v2H4V4z"
            fillRule="evenodd"
          />
        </svg>
      );
    case 1:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 2h3V0h4v2h9v14H0V2zm2 2v10h12V4H2zm9 2h2v2h-2V6zM3 9c0-1.657 1.347-3 3-3 1.657 0 3 1.347 3 3 0 1.657-1.347 3-3 3-1.657 0-3-1.347-3-3zm2-1v2h2V8H5z"
            fillRule="evenodd"
          />
        </svg>
      );
    case 2:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 8V5a3 3 0 1 0-6 0v9h9a3 3 0 0 0 0-6H8zm2-2h1a5 5 0 0 1 0 10H0V5a5 5 0 1 1 10 0v1z"
            fillRule="evenodd"
          />
        </svg>
      );
    case 3:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0h4v4H0V0zm0 6h4v4H0V6zm0 6h4v4H0v-4zM6 0h4v4H6V0zm0 6h4v4H6V6zm0 6h4v4H6v-4zm6-12h4v4h-4V0zm0 6h4v4h-4V6zm0 6h4v4h-4v-4z"
            fillRule="evenodd"
          />
        </svg>
      );
    case 4:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8c0 4.418 3.59 8 8 8 4.418 0 8-3.59 8-8 0-4.418-3.59-8-8-8-4.418 0-8 3.59-8 8zm2 0c0-3.307 2.686-6 6-6 3.307 0 6 2.686 6 6 0 3.307-2.686 6-6 6-3.307 0-6-2.686-6-6zm5 1h5V7H9V4H7v5z"
            fillRule="evenodd"
          />
        </svg>
      );
    case 5:
      return (
        <svg
          fill="#000000"
          width="50px"
          height="50px"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 16h16V0H0v16zm2-2V2h12v12H2zm4-3l5-3-5-3v6z"
            fillRule="evenodd"
          />
        </svg>
      );
    default:
      return <></>;
  }
}

const cicons = {
  File: () => IconsFunc(0),
  Camera: () => IconsFunc(1),
  Heart: () => IconsFunc(2),
  Menu: () => IconsFunc(3),
  Clock: () => IconsFunc(4),
  Video: () => IconsFunc(5),
};

export { cicons };
